@use "../config/" as *;
.waf-detail {
    --_social-size: 3.8rem;
    @extend %p-zero;
    @extend %m-x-half-neg;
    @extend %m-y-zero;
    .article-body {
        @extend %flex-column;
        @extend %p-x-full;
        @extend %m-t-three-space;
        img {
            border-radius: Var(--half-radius);
            @extend %m-y-full;
        }
        em {
            @extend %c-grey-10;
        }
    }
    video {
        @extend %m-b-full;
        @extend %w-100;
    }
    iframe {
        @extend %m-b-full;
        @extend %w-100;
    }
    .sub-title {
        @extend %c-blue-2-10;
        @extend %font-20-psb;
    }
    p,.text,li {
        @extend %m-b-full;
        @extend %c-black-6;
        @extend %font-16-pr;
    }
    ol,ul {
        @extend %p-l-full;
    }
    .article-featured-media {
        @extend %m-b-full;
    }
    .img-box {
        border-radius: Var(--half-radius);
    }
    .table-responsive {
        border-radius: var(--half-radius);
    }
    .table {
        @extend %w-100;
        thead {
            @extend %c-white-10;
            th {
                @extend %text-left;
            }
        }
    }
    .blockquote,blockquote {
        border-left: .4rem solid var(--c-orange);
        @extend %p-l-full;
        p { 
            @extend %font-20-pr;
            @extend %c-blue-2-10;
        }
    }
    .article-head {
        height: 60.2rem;
        @extend %p-y-two-space;
        @extend %p-x-full;
        @extend %relative;
        @include flex-config(flex,column,flex-end,null);
        &::before {
            content: '';
            background: linear-gradient(359.95deg, var(--c-blue) 37.46%, hsl(var(--hsl-c-blue) / 0) 54.56%);
            @extend %w-100;
            @extend %h-100;
            @extend %position-b-l;
        }
        .tagline {
            z-index: var(--zindex1);
            @extend %font-14-pr;
            @extend %c-orange-10;
        }
        .title {
            line-height: 3.6rem;
            z-index: var(--zindex1);
            @extend %p-y-full;
            @extend %c-white-10;
            @extend %font-32-pb;
        }
        .head-wrap {
            z-index: var(--zindex1);
        }
        .article-featured-media {
            z-index: var(--zindexnegative1);
            @extend %h-100;
            @extend %w-100;
            @extend %position-t-l;
            .img-box {
                border-radius: 0;
                @extend %h-100;
                img {
                    object-fit: cover;
                    @extend %h-100;
                }
            }
        }
        .article-description,p,.text {
            @extend %font-16-pr;
            @extend %c-white-10;
        }
        .meta {
            @extend %font-14-pr;
            @extend %c-blue-1-10;
            &-category {
                @extend %d-none;
            }
        }
        .article-meta {
            padding-left: 5rem;
            @extend %relative;
        }
        .meta-author {
            @extend %m-b-zero;
            @extend %flex-n-c;
            img {
                width: 3.9rem;
                height: 3.9rem;
                left: 0;
                @extend %position-v-center;
                @extend %c-blue-1-bg-10;
                @extend %circle-radius;
            }
            .text {
                @extend %m-b-zero;
            }
        }
    }
    .social {
        &-share {
            width: unset;
            height: unset;
            @include position(calc(var(--header-height) + 3.6rem), var(--full-space));
            &-wrap {
                display: block;
                position: unset;
            }
        }
        &-items {
            @extend %flex-column;
            @extend %gap-full;
        }
        &-wrap {
            background-color: unset;
        }
        &-icon {
            width: var(--_social-size);
            height: var(--_social-size);
            @extend %circle-radius;
            @extend %flex-c-c;
            @extend %c-blue-2-bg-10;
            &:first-child:hover {
                @extend %circle-radius;
            }
            i {
                @extend %font-zero;
            }
        }
    }
    .icon-add::before {
        font-size: 2.2rem;
    }
    .list-related {
        margin: 0;
        padding: 0;
        .waf-head {
            .title {
                @extend %font-20-pb;
                @extend %capitalize;
            }
        }
        .article {
            &-title {
                @extend %c-blue-2-10;
                @include truncate-vertical(3,18,20,$font-psb);
            }
            &-list {
                display: flex;
                gap: 3rem;
                flex-direction: column;
            }
            &-thumbnail,&-commentbox {
                @extend %d-none;
            }
            &-description {
                color: var(--c-blue-1);
            }
        }
        .meta-date {
            color: var(--c-grey);
        }
    }
}
.polls-section {
    @extend %p-x-full;
    @extend %m-y-two-space;
    .poll {
        &-item {
            box-shadow: 0 .2rem 1rem 0 hsl(var(--hsl-c-black) / .2);
            flex-wrap: wrap;
            @extend %half-radius;
            @extend %p-y-one-n-half;
            @extend %p-x-full;
            @extend %flex;
        }
        &-title {
            @extend %uppercase;
            @extend %m-t-zero;
            @extend %m-b-one-n-half;
            @extend %text-center;
            @extend %w-100;
            @extend %c-blue-10;
            @extend %font-18-pb;
        }
        &-percentage {
            @extend %d-none;
            .number {
                @extend %c-grey-10;
                @extend %font-32-pb;
            }
        }
        &-info {
            cursor: pointer;
            @extend %text-center;
            @include flex-config(flex,column,null,center);
        }
        &-thumbnail {
            width: 6.7rem;
            height: 6.7rem;
            @extend %m-b-half;
            .image {
                @extend %w-100;
                @extend %h-100;
            }
        }
        &-content {
            @extend %flex-column;
            .fname {
                @extend %capitalize;
                @extend %c-blue-2-10;
                @extend %font-16-pr;
            }
            .lname {
                @extend %capitalize;
                @extend %c-blue-10;
                @extend %font-16-pb;
            }
            .name {
                word-break: break-word;
            }
        }
    }
    .form-input {
        pointer-events: none;
        opacity: 0;
        @extend %w-100;
        @extend %h-100;
        @extend %position-t-l;
        &:checked {
            ~.poll-info {
                .image {
                    @extend %d-none;
                }
                .poll-percentage {
                    @extend %d-block;
                }
            }
        }
    }
    .won {
        .poll-percentage {
            .number {
                @extend %c-orange-10;
            }   
        }
    }
    .vs {
        width: 10%;
        @extend %flex-c-c;
        .text {
            @extend %uppercase;
            @extend %c-blue-10;
            @extend %font-24-pb;
            @extend %m-b-zero;
        }
    }
    .radio-box-wrap {
        width: 45%;
        transition: 1s;
        @extend %relative;
        &:hover {
            .image {
                box-shadow: 0 .1rem 1.3rem .2rem hsl(var(--hsl-c-orange)/.6)
            }
        }
    }
}
.horizontal-list {
    .waf-body {
        margin-top: var(--full-space);
    }
    .article {
        &-list {
            flex-wrap: nowrap;
            gap: var(--full-space);
            @extend %flex-column;
        }
        &-item {
            box-shadow: 0 0.5rem 2rem 0 hsla(var(--hsl-c-black)/0.1);
            margin-block: 0;
            flex-shrink: 0;
            @extend %relative;
            @extend %half-radius;
            @extend %w-100;
            @extend %c-white-bg-10;
        }
        &-wrap {
            @extend %flex;
        }
        &-info {
            @extend %c-orange-10;
            .tagline {
                a {
                    @extend %c-orange-10;
                }
            }
        }
        &-thumbnail {
            border: 0;
            width: clamp(10.3rem,30%,21.3rem);
            flex-shrink: 0;
            .img-box {
                overflow: hidden;
                @extend %h-100;
                @extend %half-radius;
            }
            .tagline {
                @extend %d-none;
            }
        }
        &-content {
            flex-wrap: wrap;
            padding: var(--half-space);
            @extend %w-100;
            @extend %flex;
        }
        &-title {
            font-weight: 400;
            @extend %c-blue-10;
            @extend %w-100;
            @include truncate-vertical(3, 14, null, $font-pr);
        }
        &-readmore {
            @extend %d-none;
        }
        &-meta {
            flex-direction: row;
            .meta {
                position: relative;
                padding-right: var(--full-space);
                margin-left: 0;
                padding-left: 0;
                &:not(:last-child) {
                    &::after {
                        position: absolute;
                        right: 0;
                        content: '|'; 
                        margin-right: .4rem;
                        @extend %c-black-6;
                    }
                }
            }
            .meta-date {
                &:before {
                    content: unset;
                }
            }
        }
    }
    .img-box {
        aspect-ratio: 2/3;
        padding-bottom: 0;
        img {
            object-fit: cover;
            object-position: top;
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-detail {
        .layout-wrapper {
            margin-inline: 0;
            max-width: 100%;
        }
        .article-body {
            flex-direction: row;
            padding-inline: 0;
            margin-inline: var(--container-white-space);
            display: grid;
            grid-template-areas: 'a b';
            grid-template-columns: 7fr 3fr;
            gap: var(--container-white-space);
            section {
                grid-area: 'b';
            }
            .article-content {
                grid-area: 'a';
            }
        }
        .list-related {
            width: calc(100% - calc(5*var(--full-space)));
            margin-left: calc(5*var(--full-space));
            .waf-head {
                margin-bottom: var(--two-space);
            }
            .article-item {
                padding-left: var(--full-space);
                border-left: .3rem solid var(--c-orange);
            }
        }
    }
    .polls-section {
        margin-inline: var(--container-white-space);
        padding-inline: 0;
        width: 70%;
        .poll {
            &-item {
                justify-content: center;
            }
            &-content {
                .name {
                    font-size: 1.8rem;
                }
            }
            &-percentage {
                .number {
                    font-size: 3.6rem;
                }
            }
        }
        .vs {
            .text {
                font-size: 2.2rem;
            }
        }
        .radio-box-wrap {
            width: 25%;
        }
    }
    .horizontal-list {
        padding-top: 6rem;
        padding-inline: 0;
        margin-inline: var(--half-space-negative);
        .layout-wrapper > * {
            width: calc((100% - var(--container-white-space)) * .7);
        }
        .article {
            &-item {
                padding: var(--full-space);
            }
            &-wrap {
                align-items: center;
            }
            &-title {
                height: auto;
                width: 90%;
            }
            &-content {
                padding-inline: var(--full-space);
                position: relative;
                align-items: center;
                a {
                    width: 100%;
                    position: relative;
                    color: hsl(var(--hsl-c-white)/1);
                    display: flex;
                }
                &::before {
                    content: "\e801";
                    width: 2.4rem;
                    height: 2.4rem;
                    right: var(--two-space);
                    display: block;
                    font-size: 1.6rem;
                    font-family: $font-icon;
                    @include color(c-white, 10);
                    @include flex-config(flex, null, center, center);
                    @include border-radius(50%);
                    @include position-combo('y-center');
                    @include bg(c-blue, 10);
                }
            }
        }
        .img-box {
            aspect-ratio: 16/9;
        }
    }
}
@media (min-width: $desktop-min-width) {
    .waf-detail {
        .article-head {
            height: unset;
            padding: 0;
            display: grid;
            background-color: var(--c-blue);
            grid-template-areas: 
                '. d'
                'a d'
                'b d'
                'c d'
                '. d';
            grid-template-columns: auto 62.7%;
            &::before {
                content: unset;
            }
            .tagline,.title, .head-wrap {
                padding-left: calc(var(--container-white-space) / 2);
            }
            .tagline {
                grid-area: a;
            }
            .title {
                margin-right: -15%;
                grid-area: b;
            }
            .head-wrap {
                grid-area: c;
            }
            .article-description {
                display: none;
            }
            .article-featured-media {
                margin-bottom: 0;
                position: relative;
                z-index: unset;
                grid-area: d;
                &::after {
                    content: '';
                    background: linear-gradient(89.99deg, var(--c-blue) 1.61%, hsl(var(--hsl-c-blue) / 0) 36.6%);
                    pointer-events: none;
                    @include position-combo(inset);
                }
                .img-box img {
                    height: unset;
                    aspect-ratio: 3/2;
                    display: block;
                    object-position: top center;
                }
            }
        }
        .social-share {
            top: 50%;
            right: 4rem;
            transform: translate(0, -50%);
        }
    }
}
@media (min-width: $large-desktop-min-width) {
    .waf-detail {
        .article-head {
            .title, .tagline, .head-wrap {
                padding-left: var(--container-white-space);
            }
            .title {
                font-size: 4.8rem;
                line-height: 6.4rem;
            }
        }
    }
}